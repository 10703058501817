import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as training from './../css/training.module.css';
import CallToAction from "../components/CallToAction";
import { useTranslation } from "react-i18next";

const Training = () => {
  const { t } = useTranslation('training');
  return (
  <Layout>
    <Seo title={t("seoTitle")} />
      <section>
        <div id={training.firstSection} className={training.sectionContent}>
          <h2>{t("heading")}</h2>
          <p>{t("section1P1")}</p>
          <p>{t("section1P2")}</p>
          
          <p className={training.callOutBlue}>{t("section1CallOut")}</p>
         
        </div>
      </section>

      <section>
        <CallToAction />
      </section>
    
    
  </Layout>
)}

export default Training
