import React from 'react';
import * as action from './../css/callToAction.module.css';
//import { Link } from 'gatsby';
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next";


export default function CallToAction() {
    const { t } = useTranslation();
    return(
    <div className={action.contentBlock}>
        
        <Link to="/contact">
            <button className={action.contactBtn}>
                {t("callToActionBtn")}
            </button>
        </Link>
    </div>
    )
}
